<script lang="ts" setup>
const props = defineProps<{
  src?: string | null,
  alt: string,
  showAlt: boolean
}>()

const isImageHidden = ref(false)
const hideImage = () => isImageHidden.value = true
</script>

<template>
  <figure>
    <nuxt-img :src="src" :alt="alt" v-if="src && isImageHidden" @onerror="hideImage" width="500" />
    <figcaption v-if="showAlt">{{ alt }}</figcaption>
  </figure>
</template>

<style lang="postcss" scoped>
  figure {
    display: grid;
    grid-template: "container";
    place-items: center;
    place-content: center;
    overflow: hidden;
    width: var(--image-width, 1em);
    height: var(--image-height, 1em);
    border-radius: var(--image-border-radius, var(--border-radius-rounded));
    overflow: hidden;
    background-color: var(--image-background-color, var(--primary-color));
    color: var(--image-text-color, var(--text-color));
    margin: 0;
    padding: 0;
    & img {
      grid-area: container;
      object-fit: cover;
      height: 100%;
      width: 100%;
      font-size: .4em;
    }
    & figcaption {
      grid-area: container;
      font-size: .4em;
      font-weight: 600;
    }
  }
</style>
